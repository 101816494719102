<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" lg="6" xl="5">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <h1 class="title">Contact Us at Silent Auction Vacations</h1>
          </v-card-text>

          <v-card-text
            class="text--primary body-1"
            v-if="!success && !submissionError"
          >
            <p>
              Please enter your message below. You'll receive a reply by email
              within 3 business days (usually sooner).
            </p>
            <p class="mb-0">Thank you!</p>
          </v-card-text>
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="onSubmit"
              v-if="!success && !submissionError"
            >
              <v-text-field
                label="Name *"
                v-model.trim="name"
                :rules="[textRules.required]"
                :loading="loading"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                @input="email ? (email = email.toLowerCase()) : ''"
                v-model.trim="email"
                label="Email *"
                type="email"
                :rules="[textRules.required, emailRules.valid]"
                :loading="loading"
                :disabled="loading"
              ></v-text-field>

              <v-textarea
                v-model.trim="message"
                label="Message *"
                :rules="[textRules.required]"
                :loading="loading"
                :disabled="loading"
              ></v-textarea>
            </v-form>
            <!-- alerts -->
            <v-alert
              outlined
              type="success"
              icon="mdi-check-circle-outline"
              v-if="success"
            >
              You've successfully submitted your contact request. You'll receive
              a reply by email within 3 business days (usually sooner). Thank
              You!
            </v-alert>
            <v-alert
              outlined
              type="error"
              icon="mdi-alert-outline"
              v-if="submissionError"
            >
              The form did not submit successfully. Please email us directly at
              <strong
                ><a href="mailto:booking@silentauctionvacations.com"
                  >booking@silentauctionvacations.com</a
                ></strong
              >. We apologize for any inconvenience. Thank you.
            </v-alert>

            <v-alert
              class="mt-6"
              outlined
              type="error"
              icon="mdi-alert-outline"
              v-if="validationError"
            >
              Please check the form for errors and complete all fields.
            </v-alert>

            <!-- alerts -->
          </v-card-text>
          <v-card-actions v-if="!success && !submissionError">
            <v-btn color="error" outlined @click="$router.push('/')" depressed
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="onSubmit"
              :loading="loading"
              :disabled="loading"
            >
              Contact Us</v-btn
            ></v-card-actions
          >
          <v-card-actions v-else>
            <v-spacer />
            <v-btn color="primary" depressed @click="$router.push('/')"
              >Go Back Home</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "@/plugins/firebase";
import formRules from "@/mixins/formRules";
export default {
  mixins: [formRules],
  data: () => ({
    name: "",
    email: "",
    message: "",
    success: false,
    submissionError: false,
    validationError: false,
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("setLoading", true);
        let message = {
          name: this.name,
          email: this.email,
          message: this.message,
          dateCreated: new Date().toISOString().substring(0, 10),
        };
        // add message to firestore
        let generalContactsRef = firebase
          .firestore()
          .collection("tpContactForm");
        await generalContactsRef.add(message);
        try {
          let request = await axios.post("/api/contact", { message: message });
          if (request.status == 200) {
            this.success = true;
            this.submissionError = false;
            this.validationError = false;
          }
        } catch (error) {
          this.submissionError = true;
          this.validationError = false;
          this.success = false;
        }
        this.$store.dispatch("setLoading", false);
        return;
      }
      this.validationError = true;
    },
  },
};
</script>